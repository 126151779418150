<template>
  <div class="login-page auth-wrap">


    <div class="auth-head">
      <div class="auth-head__logo">
        <a :href="'https://skladusa.com/' + getLangPrefix" target="_blank">
          <img src="/img/login-group/login-logo.png" alt="alt">
        </a>
      </div>
    </div>

    <router-view></router-view>

  </div>

</template>

<script>

  export default {
    name: "Auth",

    data() {
      return{

      }
    },

    computed: {
      getLangPrefix() {
        if(this.$store.getters.GET_LANG === 'ua') return ''
        return this.$store.getters.GET_LANG
      }
    },
  }
</script>

<style scoped>

</style>
