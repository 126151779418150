import Language from '../../components/Langueage/Language.vue'

export default {
	name: "Successful",
	components: {
		Language,
	},
	mounted() {
		this.$gtag.event( 'conversion', {'send_to': 'AW-11108205901/9yFtCIHjgZEYEM2K57Ap'});
	}
}
