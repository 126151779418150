<template>
  <div class="dashboard-upc-code">
    <div class="section-label large"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['menu_exportRequests',])"></div>
      {{$t('menu_exportRequests.localization_value.value')}}
    </div>
    <div class="dashboard-upc-code__item">
      <div class="dashboard-upc-code__body">
        <div class="dashboard-upc-code__head ">
          <div class="dashboard-upc-code__head-title mr-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['dashboard_exportRequestsExport',])"></div>
            {{$t('dashboard_exportRequestsExport.localization_value.value')}}
          </div>
          <div class="dashboard-upc-code__head-btn"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['dashboard_exportRequestsTable',])"></div>
            <router-link :to="$store.getters.GET_PATHS.exportRequests">
              <MainButton
                  class="white-space-line mw-100"
                  :value="$t('dashboard_exportRequestsTable.localization_value.value')"
                  :ico="true"
              >
                <template slot="ico">
                  <DownloadIco2/>
                </template>
              </MainButton>
            </router-link>
          </div>
        </div>
        <div class="dashboard-upc-code__content" style="min-height: 37px;">
          <div class="dashboard-upc-code__content-inner"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['dashboard_exportRequestsText'])"></div>
            <p>{{$t('dashboard_exportRequestsText.localization_value.value')}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
import DownloadIco2 from "../../../../../../public/img/UI-group/download-icon-2.svg?inline"

export default {
  name: "ExportRequests",
  components: {
    MainButton,
    DownloadIco2,
  },

  data(){
    return{

    }
  },

  mounted() {

  },

  methods: {
  },
}
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";
  @import "../../../../../scss/colors";


  .dashboard-upc-code{
    //max-width: 300px;
    width: 100%;

    @include for-550{
      max-width: 100%;
    }

    &__item{
      background: white;
      box-shadow: 0px 4px 16px #e7dfd5;
      border-radius: 5px;
      //height: 100%;
      min-height: 140px;
    }

    &__body{
      padding: 15px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__head{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      height: 100%;
      align-items: center;
    }

    &__head-title{
      display: flex;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $black;
      align-items: center;
      width: 100%;
    }

    &__head-btn{
      max-width: 120px;
      width: 100%;
    }

    &__content{
      width: 100%;
    }

    &__content-inner{
      font-size: 14px;

      > p{
        margin-top: 0;
        margin-bottom: 5px;
      }
    }

  }


</style>
