<template>
  <div class="dashboard-upc-code">
    <div class="section-label large"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['dashboard_UPSCode',])"></div>
      {{$t('dashboard_UPSCode.localization_value.value')}}
    </div>
    <div class="dashboard-upc-code__item">
      <div class="dashboard-upc-code__body">
        <div class="dashboard-upc-code__head">
          <div class="dashboard-upc-code__head-title mr-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['dashboard_UPCStatistics',])"></div>
            {{$t('dashboard_UPCStatistics.localization_value.value')}}
          </div>
          <div class="dashboard-upc-code__head-btn"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['dashboard_UPSImport',])"></div>
            <MainButton
                class="white-space-line mw-100"
                :value="$t('dashboard_UPSImport.localization_value.value')"
                :ico="true"
                @click.native="changeUPCCodesImport(true)"
            >
              <template slot="ico">
                <DownloadIco2/>
              </template>
            </MainButton>
          </div>
        </div>
      <div class="dashboard-upc-code__content">
        <div class="dashboard-upc-code__content-inner"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['dashboard_SufficientNumberCodes','dashboard_FreeCodes'])"></div>
          <p>{{$t('dashboard_SufficientNumberCodes.localization_value.value')}}</p>
          <div class="amount-row font-weight-normal">
            {{$t('dashboard_FreeCodes.localization_value.value')}}
            <span class="font-weight-normal">{{countFreeCodes}}</span>
          </div>
        </div>
      </div>
    </div>
    </div>

    <UPCCodesImport
        v-if="isModalUPCCodesImport"
        @reloadCodes="getCountFreeUPCCodes"
        @close="changeUPCCodesImport(false)"
    />
  </div>
</template>

<script>
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
import UPCCodesImport from "../../popups/UPCCodesImport/UPCCodesImport";
import DownloadIco2 from "../../../../../../public/img/UI-group/download-icon-2.svg?inline"

export default {
  name: "UPSCode",
  components: {
    MainButton,
    UPCCodesImport,
    DownloadIco2,
  },

  data(){
    return{
      isModalUPCCodesImport: false,
      countFreeCodes: 0,
    }
  },

  mounted() {
    this.getCountFreeUPCCodes()
  },

  methods: {
    changeUPCCodesImport(val){
      this.isModalUPCCodesImport = val
    },

    getCountFreeUPCCodes() {
      this.$store.dispatch('getCountFreeUPCCodes').then(response => {
        this.countFreeCodes = this.getRespData(response)?.count_free_upc_codes
      })
    }
  },
}
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";
  @import "../../../../../scss/colors";


  .dashboard-upc-code{
    //max-width: 300px;
    width: 100%;

    @include for-550{
      max-width: 100%;
    }

    &__item{
      background: white;
      box-shadow: 0px 4px 16px #e7dfd5;
      border-radius: 5px;
      //height: 100%;
      min-height: 140px;
    }

    &__body{
      padding: 15px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__head{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      height: 100%;
      align-items: center;
    }

    &__head-title{
      display: flex;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $black;
      align-items: center;
      width: 100%;
    }

    &__head-btn{
      max-width: 120px;
      width: 100%;
    }

    &__content{
      width: 100%;
    }

    &__content-inner{
      font-size: 14px;

      > p{
        margin-top: 0;
        margin-bottom: 5px;
      }
    }

  }


</style>
