<template>
  <Modal
      @close="$emit('close')"
      class="address-editing-modal statistic-popup"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['dashboard_sendCheck'])"></div>
        {{$t(`dashboard_sendCheck.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="address-editing__content">
        <div class="section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['dashboard_sendCheckDecs'])"></div>
          {{$t(`dashboard_sendCheckDecs.localization_value.value`)}}
        </div>

        <div class="address-editing__row custom-row">
<!--          <div class="address-editing__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;xs-100"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['common_email'])"></div>-->
<!--            <DefaultInput-->
<!--                :label="$t('common_email.localization_value.value')"-->
<!--                v-bind:class="{'ui-no-valid': NPP.validation.email}"-->
<!--                :error="NPP.validation.email"-->
<!--                :errorTxt="$t(`${NPP.validationTranslate.email}.localization_value.value`)"-->
<!--                v-model="NPP.newPayments.email"-->
<!--            />-->
<!--          </div>-->
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--            <div class="admin-edit" @click="editTranslate(['common_CreateRAMPFirstName'])"></div>-->
            <DefaultInput
                :label="$t('importO_transactionNumber.localization_value.value')"
                v-bind:class="{'ui-no-valid': NPP.validation.transaction}"
                :error="NPP.validation.transaction"
                :errorTxt="$t(`${NPP.validationTranslate.transaction}.localization_value.value`)"
                v-model="NPP.newPayments.transaction"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--            <div class="admin-edit" @click="editTranslate(['common_CreateRAMPFirstName'])"></div>-->
            <InputSum
                :label="$t('common_Amount.localization_value.value')"
                :icoType="'dollar'"
                @change="changeValueQuantity"
                :placeholder="'0'"
                v-bind:class="{'ui-no-valid': NPP.validation.amount}"
                :errorTxt="$t(`${NPP.validationTranslate.amount}.localization_value.value`)"
                :error="NPP.validation.amount"
                :value="NPP.newPayments.amount"
            />
<!--            <DefaultInput-->
<!--                :label="$t('common_Amount.localization_value.value')"-->
<!--                v-bind:class="{'ui-no-valid': NPP.validation.amount}"-->
<!--                :error="NPP.validation.amount"-->
<!--                :errorTxt="$t(`${NPP.validationTranslate.amount}.localization_value.value`)"-->
<!--                v-model="NPP.newPayments.amount"-->
<!--            />-->
          </div>
          <div class="address-editing__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_CreateRAMPLastName'])"></div>
            <TextareaDefault
                :label="$t('payments_comment.localization_value.value')"
                v-bind:class="{'ui-no-valid': NPP.validation.comment}"
                :errorTxt="$t(`${NPP.validationTranslate.comment}.localization_value.value`)"
                :error="NPP.validation.comment"
                v-model="NPP.newPayments.comment"
            />
          </div>
        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_update'])"></div>
        <span class="site-link site-link--alt address-editing__btn-i mr-3"
              @click="$emit('close')">
                {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_send.localization_value.value')"
            class="address-editing__btn-i"
            @click.native="send"
            v-bind:class="{'disabled-btn' : $store.getters.getNewPaymentsPayoneerBtn}"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/commonModals/Modal";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
import {NewPaymentsPayoneer} from "@/components/modules/NewPaymentsPayoneerModule/models/NewPaymentsPayoneer";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import InputSum from "@/components/UI/inputs/InputSum/InputSum";
export default {
name: "CreatePayoneerCheckPopup",
  components: {InputSum, MainButton, TextareaDefault, DefaultInput, Modal},

  data() {
    return {
      NPP: new NewPaymentsPayoneer(),
    }
  },

  methods: {
    send() {
      if(!this.NPP.createCheckPayoneerValidate()) return

      let data = this.NPP.createCheckPayoneerPrepare()

      return this.$store.dispatch('createCheckPayoneer', data).then((response) => {

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', {txtServer: data?.message})

            if(!this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER)) {
              this.$store.commit('addCurrentUserRights', {
                key: this.PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER,
                item: {
                  name: this.PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER
                }
              })
            }

            this.$emit('close')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

        return response
      })

    },

    changeValueQuantity(data) {
      this.NPP.setAmount(data)
    },
  }

}
</script>

<style scoped>

</style>