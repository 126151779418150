<template>
  <div class="calc-ship" v-if="list"
       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
    <div class="admin-edit" @click="editTranslate([
        'dashboard_calculateEngravingShipping',
        'dashboard_calculateShipping',
        'dashboard_smsChat',
        'dashboard_deliveryStatistics',
        'dashboard_balance',
        'dashboard_balancePayoneer',
        'dashboard_calculateFunds'
      ])"></div>
<!--    <vue-custom-scrollbar class="calc-ship__content-scrollbar site-custom-scroll-x" :settings="settings">-->

<!--      <div class="calc-ship__item calc-ship__item-first">-->
<!--        <div class="calc-ship__item-content">-->
<!--          <div class="calc-ship__item-first-text">-->
<!--            Calculate Shipping-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <draggable
          class="list-group calc-ship__list"
          v-model="localList"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          item-key="order"
          :handle="draggableHandle"
          @input="changeList"
      >
        <div class="calc-ship__item"
             :class="{'calc-ship__item-first draggable-element' : element.firstElement,
             'draggable-element' : !element.firstElement}"
             v-for="element in list"
             :key="element.id"
        >
          <router-link
              :to="element.linkPath"
              class="calc-ship__item-content"
              :class="{'brown' : element.fillColor}"
              v-if="element.firstElement == true"
          >
            <div class="calc-ship__item-first-text" >
              {{ element.firstElementText }}
            </div>
          </router-link>
          <div class="calc-ship__item-content"
               :class="{'brown' : element.fillColor}"
               v-else
          >
            <div class="calc-ship__item-ico">
              <img :src="element.ico"
                   :style="element.icoStyle"
                   alt="ico"
                   class="calc-ship__item-ico-i"
              >
            </div>
            <div class="calc-ship__item-body">
              <div class="calc-ship__item-on-hold"
                   v-if="_.has(element, 'bigText2') && element.bigText2">
                <div class="calc-ship__item-big-txt">
                  {{element.bigText2}}
                </div>
                <template v-if="element.textFromLink2">
                  <div
                          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit ml--3" @click="editTranslate(['dashboard_OnHold'])"></div>
                    <router-link v-if="element.textFromLinkHref2" :to="element.textFromLinkHref2">
                      {{element.textFromLink2}}
                    </router-link>
                    <a v-else-if="element.textFromLinkFunc2" href="javascript:void(0)" @click="element.textFromLinkFunc2">
                      {{element.textFromLink2}}
                    </a>
                  </div>

                </template>
              </div>


              <div class="calc-ship__item-big-txt">
                {{element.bigText}}
              </div>

              <template v-if="element.links">
                <div class="d-flex mt-1"
                     v-for="(item, index) in element.links"
                     :key="index"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
                >
                  <div v-if="item.linkTextTranslate" class="admin-edit ml--3" @click="editTranslate([item.linkTextTranslate])"></div>

                  <template v-if="item.linkPath && item.linkPath !== ''">
                    <router-link
                            class="calc-ship__item-link ml-1"
                            :to="item.linkPath">
                      {{item.linkText}}
                    </router-link>
                  </template>
                  <a v-else-if="item.useFunction"
                     href="javascript:void(0)"
                     class="calc-ship__item-link ml-1"
                     @click="item.useFunction"
                  >
                    <div class="calc-ship__item-big-txt" v-if="item.bigText">
                      {{item.bigText}}
                    </div>
                    <span v-else>
                      {{item.linkText}}
                    </span>
                  </a>
                  <a v-else
                     href="javascript:void(0)"
                     class="calc-ship__item-link ml-1">
                    <div class="calc-ship__item-big-txt" v-if="item.bigText">
                      {{item.bigText}}
                    </div>
                    <span v-else>
                      {{item.linkText}}
                    </span>
                  </a>
                </div>
              </template>

              <div class="d-flex" v-else>
                <template v-if="element.textFromLink">
                  <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div v-if="element.textFromLinkTranslate" class="admin-edit ml--3" @click="editTranslate([element.textFromLinkTranslate])"></div>
                    <router-link :to="element.textFromLinkHref">
                      {{element.textFromLink}}
                    </router-link>
                  </div>
                </template>
                <template v-else>
                  <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div v-if="element.textTranslate" class="admin-edit ml--3" @click="editTranslate([element.textTranslate])"></div>
                    {{element.text}}
                  </div>
                </template>

                <template v-if="element.linkPath && element.linkPath !== ''">
                  <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div v-if="element.linkTextTranslate" class="admin-edit ml--3" @click="editTranslate([element.linkTextTranslate])"></div>
                    <router-link
                            class="calc-ship__item-link"
                            v-bind:class="{'ml-1': element.text && element.text.length > 0}"
                            :to="element.linkPath">
                      {{element.linkText}}
                    </router-link>
                  </div>
                </template>

                <div v-else
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div v-if="element.linkTextTranslate" class="admin-edit ml--3" @click="editTranslate([element.linkTextTranslate])"></div>
                  <a href="javascript:void(0)"  class="calc-ship__item-link"
                     v-bind:class="{'ml-1': element.text && element.text.length > 0}">
                    {{element.linkText}}
                  </a>
                </div>


              </div>




              <div class="calc-ship__item-plus"></div>
            </div>
          </div>
        </div>
      </draggable>

<!--    </vue-custom-scrollbar>-->
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  // import vueCustomScrollbar from 'vue-custom-scrollbar';
  // import "vue-custom-scrollbar/dist/vueScrollbar.css"


  export default {
    name: "CalculateShipping",
    components: {
      draggable,
      // vueCustomScrollbar
    },

    // display: "Transitions",

    props: {
      list: {
        type: Array,
      },
    },

    watch: {
      list: function (newVal){
        this.localList = newVal
      },
    },

    computed: {
      dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      },
    },

    data(){
      return {
        settings: {
          // suppressScrollY: true,
          // suppressScrollX: false,
          // wheelPropagation: false,

          suppressScrollY: true,
          suppressScrollX: false,
          wheelPropagation: false,
          useBothWheelAxes: true,

          wheelSpeed: 0.5,
          swipeEasing: true,
          scrollXMarginOffset: 16,
        },

        localList: this.list,

        // userBalance: 0,

        // list: [
        //
        // ],

        drag: false,

        draggableHandle: '.draggable-element',
        isMobile: '',
      }
    },

    created() {
      // this.list = [
      //   {
      //     firstElement: true,
      //     firstElementText: 'Calculate Shipping',
      //   },
      //   {
      //     ico: '/img/dashboard-group/balance-icon.svg',
      //     bigText: '$' + this.userBalance,
      //     textFromLink: 'My Balance',
      //     textFromLinkHref: this.$store.getters.GET_PATHS.balanceHistory,
      //     // text: 'My Balance',
      //   },
      //   {
      //     ico: '/img/dashboard-group/returned-packages-icon.svg',
      //     bigText: '$200',
      //     text: 'Returned Packages',
      //   },
      //   {
      //     ico: '/img/dashboard-group/unidentified-packages-icon.svg',
      //     bigText: '20',
      //     text: 'Unidentified Packages',
      //   },
      //   {
      //     ico: '/img/dashboard-group/invoices-icon.svg',
      //     bigText: '$594',
      //     text: 'Invoices',
      //   },
      //   {
      //     ico: '/img/dashboard-group/shops-icon.svg',
      //     bigText: '5',
      //     text: 'Shops',
      //     linkText: 'Connect New',
      //     linkPath: ''
      //   },
      //   {
      //     ico: '/img/dashboard-group/listings-icon.svg',
      //     bigText: '40',
      //     text: 'Listings',
      //     linkText: 'Create New',
      //     linkPath: ''
      //   },
      //   {
      //     ico: '/img/dashboard-group/ship-to-amazon-icon.png',
      //     bigText: '10',
      //     text: 'Ship to Amazon',
      //   },
      //   {
      //     ico: '/img/dashboard-group/fba-invoices-icon.svg',
      //     bigText: '54',
      //     text: 'FBA Invoices',
      //   },
      //   {
      //     ico: '/img/dashboard-group/paypal-transactions-icon.png',
      //     bigText: '$340',
      //     text: 'PayPal Transactions',
      //   },
      // ]
    },

    methods: {
      changeList(val){
        this.$emit('changeInfoCardsList', val)
      },


    },

    mounted(){
      window.innerWidth < 1025 ? this.isMobile = true : this.isMobile = false;
      this.isMobile == true ? this.draggableHandle = 'disabled' : null;

      // if(!Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'balance')) {
      //   let interval = setInterval(() => {
      //     if(Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'balance')){
      //       this.list[1].bigText = '$' + this.$store.getters.getUserProfile.balance
      //       clearInterval(interval)
      //     }
      //   },50)
      // } else {
      //   this.list[1].bigText = '$' + this.$store.getters.getUserProfile.balance
      // }
    },

  }

</script>

<style lang="scss">
  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
  }

  .calc-ship{
    display: flex;

    &__content-scrollbar{
      display: flex;
      width: 100%;

    }

    &__list{
      display: flex;
      flex-wrap: wrap;
      margin:  0 -8px;
      width: 100%;
      margin-top: -15px;

      @include for-1120{
        margin:  0 -4px;
      }

      @include for-550{
        width: fit-content;
      }
    }

    &__item{
      padding: 0 8px;
      margin-top: 15px;

      @include for-1120{
        padding: 0 4px;
      }

      @include for-680{
        width: 50%;
      }

      @include for-420{
        width: 100%;
      }
    }

    &__item-content{
      height: 129px;
      display: flex;
      flex-direction: column;
      padding: 12px 5px 12px 12px;
      box-shadow: 0px 4px 16px #E7DFD5;
      border-radius: 5px;
      background-color: white;
      min-width: 159px;
      position: relative;
      min-height: 75px;
      max-width: 165px;

      @include for-1120{
        max-width: 88px;
        min-width: 132px;
        padding: 10px 5px 10px 10px;
      }

      @include for-680{
        min-width: initial;
        max-width: 100%;
        flex-direction: row;
        height: 100%;
        padding: 8px;
      }
    }

    .brown{
      background: $brown;
    }

    .brown &__item-big-txt,
    .brown &__item-link{
      color: white;
    }

    &__item-first{

    }

    &__item-first &__item-content{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      background-color: $brown;
      padding: 12px;
      max-width: 140px;
      width: 100%;


      @include for-1120{
        max-width: 88px;
      }

      @include for-680{
        max-width: 100%;
      }
    }

    &__item-first-text{
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      text-align: center;
      color: white;

      @include for-1120{
        font-size: 12px;
        line-height: 12px;
      }
    }

    &__item-ico{
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include for-1120{
        width: 24px;
        height: 24px;
        margin-bottom: 14px;
      }

      @include for-680{
        width: 100%;
        height: 16px;
        max-width: 16px;
        min-width: 16px;
        margin-right: 10px;
      }

      img{

        @include for-1120 {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__item-ico-i{

    }

    &__item-body{
      margin-top: auto;
      font-size: 12px;
      line-height: 18px;
      color: $brown;

      a{
        color: $brown;
      }

      @include for-1120{
        margin-top: initial;
      }

      @include for-680{
        display: flex;
        flex-wrap: wrap;
        white-space: nowrap;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &__item-big-txt{
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;
      color: $black;

      @include for-1120{
        font-size: 14px;
        line-height: 15px;
      }

      @include for-680{
        width: 100%;
        padding-top: 2px;
      }
    }

    &__item-on-hold{
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      margin-top: -25px;
      position: relative;
      top: -10px;
      right: 4px;

      @include for-680{
        flex-direction: column;
        align-items: flex-start;
        margin-top: -4px;
        top: -2px;
      }

      .calc-ship__item-big-txt {
        font-size: 16px;
        line-height: 12px;

        @include for-680 {
          font-size: 14px;
          line-height: 12px;
        }
      }
    }

    &__item-link{
      color: $accent;
      text-decoration: underline;

      @include for-1120{
        font-size: 12px;
        line-height: 13px;
      }

      @include for-680{
        white-space: nowrap;
      }

      &:hover{
        text-decoration: none;
      }
    }


    &__item-plus{
      position: absolute;
      right: 4px;
      //bottom: -8px;
      top: 4px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: url("../../../../../assets/img/dashboard-group/plus-calc-ship-icon.svg") center/contain no-repeat;
      transition: .3s;
      cursor: pointer;
      z-index: -1;
      visibility: hidden;
      opacity: 0;

      @include for-1120{
        top: auto;
        bottom: 4px;
        z-index: 1;
        opacity: 1;
        visibility: visible;
      }

      @include for-680{
        right: auto;
        left: 4px;
        bottom: 6px;
      }
    }

    &__item-content:hover  &__item-plus{
      z-index: 1;
      visibility: visible;
      opacity: 1;
    }

  }

  .ml--3{
    margin-left: -1rem;
  }
</style>
