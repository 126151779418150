import { render, staticRenderFns } from "./DashboardUser.vue?vue&type=template&id=ab465738&scoped=true"
import script from "./DashboardUser.vue?vue&type=script&lang=js"
export * from "./DashboardUser.vue?vue&type=script&lang=js"
import style0 from "./DashboardUser.vue?vue&type=style&index=0&id=ab465738&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab465738",
  null
  
)

export default component.exports