<template>
  <div class="registration-form">
    <div class="registration-form__inner">
      <div class="registration-form__head">
        <div class="registration-form__head-title">
          <div class="registration-form__head-title-i">
            {{ $t('registration_title.localization_value.value') }}
          </div>
          <div class="registration-form__head-title-desc">
            {{ $t('registration_subTitle.localization_value.value') }}
          </div>
        </div>
        <div class="registration-form__head-link">
          <div class="registration-form__head-lang">
            <Language/>
          </div>
          <div class="registration-form__head-social" v-if="!useFB && false">
            <div class="registration-form__head-social-i auth-form__btn-fb btn-style">
              <v-facebook-login v-model="model" @sdk-init="handleSdkInit" @login="loginFb" app-id="394048247971484" >
                <template slot="login"><Fb/></template>
                <template slot="logo"><span style="display: none"></span></template>
                <template slot="working"><Fb/></template>
                <template slot="logout"><Fb/></template>
              </v-facebook-login>
            </div>
          </div>
        </div>
      </div>

      <div class="registration-form__section">
        <div class="registration-form__title">
          {{ $t('registration_personalInformation.localization_value.value') }}
        </div>
        <div class="registration-form__row">
          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.name}"
                  :error="validation.name"
                  :errorTxt="serverError ? validationTxt.name : $t(`${validationTranslate.name}.localization_value.value`)"
                  :label="$t('common_name.localization_value.value')"
                  :type="'text'"
                  :name="'fname'"
                  v-model="name"
                  @onEnter="registration"
              />
            </div>
          </div>
          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.surName}"
                  :error="validation.surName"
                  :errorTxt="serverError ? validationTxt.surName : $t(`${validationTranslate.surName}.localization_value.value`)"
                  :label="$t('common_surName.localization_value.value')"
                  :type="'text'"
                  :name="'lname'"
                  v-model="surName"
                  @onEnter="registration"
              />
            </div>
          </div>
          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.phone}"
                  :error="validation.phone"
                  :errorTxt="serverError ? validationTxt.phone : $t(`${validationTranslate.phone}.localization_value.value`)"
                  :label="$t('common_phone.localization_value.value')"
                  :type="'tel'"
                  :name="'phone'"
                  v-model="phone"
                  @onEnter="registration"
              />
            </div>
          </div>
          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.email}"
                  :error="validation.email"
                  :errorTxt="serverError ? validationTxt.email : $t(`${validationTranslate.email}.localization_value.value`)"
                  :label="$t('login_email.localization_value.value')"
                  :type="'email'"
                  :name="'email'"
                  :autocompleteOff="true"
                  v-model="email"
                  @onEnter="registration"
              />
            </div>
          </div>
          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.createPassword}"
                  :error="validation.createPassword"
                  :errorTxt="serverError ? validationTxt.createPassword : $t(`${validationTranslate.createPassword}.localization_value.value`)"
                  :label="$t('login_createPassword.localization_value.value')"
                  :type="'password'"
                  :name="'password'"
                  :autocompleteOff="true"
                  v-model="createPassword"
                  @onEnter="registration"
              />
            </div>

            <div class="mt-3 wfc d-flex">
              <div class="password-help">
                {{ $t('common_passwordHelp.localization_value.value') }}
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
                >
                  <TooltipBtn
                      class="tooltip-target"
                  />
                  <template slot="popover">
<!--                    <div class="password-help__tooltip" v-html="$t('common_passwordHelpDetail.localization_value.value')"></div>-->
                    <div class="password-help__tooltip">
                      <PasswordRequirements
                          :value="createPassword"
                          :visibleValidation="visibleValidation"
                      />
                      <div style="font-size: 13px; margin-bottom: 7px;">
                        {{ $t('common_passwordExpiration.localization_value.value') }}
                      </div>
                    </div>
                  </template>
                </v-popover>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="registration-form__section">
        <div class="registration-form__title">
          {{ $t('registration_location.localization_value.value') }}
        </div>
        <div class="registration-form__row">
          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.address}"
                  :error="validation.address"
                  :errorTxt="serverError ? validationTxt.address : $t(`${validationTranslate.address}.localization_value.value`)"
                  :label="$t('common_address.localization_value.value')"
                  :type="'text'"
                  :name="'address'"
                  v-model="address"
                  @onEnter="registration"
              />
            </div>
          </div>
          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultInput
                  v-bind:class="{'ui-no-valid': validation.city}"
                  :error="validation.city"
                  :errorTxt="serverError ? validationTxt.city : $t(`${validationTranslate.city}.localization_value.value`)"
                  :label="$t('common_city.localization_value.value')"
                  :type="'text'"
                  :name="'city'"
                  v-model="city"
                  @onEnter="registration"
              />
            </div>
          </div>
          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultSelect
                      v-bind:class="{'ui-no-valid': validation.selectedCountry}"
                      :error="validation.selectedCountry"
                      :errorTxt="serverError ? validationTxt.selectedCountry : $t(`${validationTranslate.selectedCountry}.localization_value.value`)"
                      :options="options"
                      @change="changeCountry"
                      :selected="selectedCountry"
                      :label="$t('common_country.localization_value.value')"
                      :optionsLabel="'label'"
              />
            </div>
          </div>

          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultSelect
                      v-bind:class="{'ui-no-valid': validation.stateDistrict}"
                      :error="validation.stateDistrict"
                      :errorTxt="serverError ? validationTxt.stateDistrict : $t(`${validationTranslate.stateDistrict}.localization_value.value`)"
                      :options="optionsDistrict.length > 0 ? optionsDistrict : []"
                      @change="changeDistrict"
                      :selected="optionsDistrict.length > 0 ? stateDistrict.label : ''"
                      :label="$t('common_regions.localization_value.value')"
                      :optionsLabel="'label'"
              />
            </div>
          </div>

          <div class="registration-form__col">
            <div class="registration-form__input">
              <DefaultInput
                  :label="$t('common_postNumber.localization_value.value')"
                  :type="'text'"
                  :name="'postNumber'"
                  v-model="postNumber"
                  v-bind:class="{'ui-no-valid': validation.postNumber}"
                  :error="validation.postNumber"
                  :errorTxt="serverError ? validationTxt.postNumber : $t(`${validationTranslate.postNumber}.localization_value.value`)"
                  @onEnter="registration"
              />
            </div>
          </div>

          <div class="registration-form__col" v-if="fromSNG">
            <div class="registration-form__input">
              <DefaultInput
                      v-bind:class="{'ui-no-valid': validation.itn}"
                      :error="validation.itn"
                      :errorTxt="serverError ? validationTxt.itn : $t(`${validationTranslate.itn}.localization_value.value`)"
                      :label="$t('registration_accountItn.localization_value.value')"
                      :type="'text'"
                      :name="'itn'"
                      v-model="itn"
                      @onEnter="registration"
              />
            </div>
          </div>
        </div>
      </div>



      <div class="registration-form__section">
        <div class="registration-form__row">
<!--          <div class="registration-form__col">-->
<!--            <div class="registration-form__title">-->
<!--              {{ $t('registration_accountDetails.localization_value.value') }}-->
<!--            </div>-->

<!--            <div class="registration-form__input">-->
<!--              <DefaultInput-->
<!--                  v-bind:class="{'ui-no-valid': validation.loginShop}"-->
<!--                  :error="validation.loginShop"-->
<!--                  :errorTxt="serverError ? validationTxt.loginShop : $t(`${validationTranslate.loginShop}.localization_value.value`)"-->
<!--                  :label="'Login / Shop'"-->
<!--                  :type="'text'"-->
<!--                  v-model="loginShop"-->
<!--                  @onEnter="registration"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
          <div class="registration-form__col">
            <div class="registration-form__title">
              {{ $t('registration_fullfilment.localization_value.value') }}

              <span class="registration-form__title-prompt" v-if="customValidation.fullFilment">
                {{ $t('error_required.localization_value.value') }}
              </span>
            </div>
            <div class="registration-form__inputs-row">
              <div class="registration-form__inputs-col">
                <DefaultCheckbox
                    :label="$t('registration_byAmazon.localization_value.value')"
                    v-model="checkedAmazon"
                />
              </div>
              <div class="registration-form__inputs-col">
                <DefaultCheckbox
                        :label="$t('registration_byMerchant.localization_value.value')"
                    v-model="checkedMerchant"
                    :helpText="$t('registration_byMerchantCaption.localization_value.value')"
                />
              </div>
            </div>
          </div>



          <!--<div class="registration-form__col">-->
            <!--<div class="registration-form__title">-->
              <!--Functionality-->


              <!--<v-popover-->
                  <!--class="site-tooltip"-->
                  <!--:disabled="!tooltipFunctionalityActive"-->
                  <!--offset="5"-->
                  <!--placement="top"-->

              <!--&gt;-->

                <!--<TooltipBtn-->
                    <!--class="tooltip-target"-->

                <!--/>-->

                <!--<template slot="popover">-->

                  <!--<p>-->
                    <!--<b>Simplified Functionality</b>-->
                  <!--</p>-->
                  <!--<p>-->
                    <!--Our platform becomes your shipping partner: you describe your product, point dimensions and pay the shipping price-->
                    <!--<br>-->
                    <!--<a href="#">-->
                      <!--Learn More-->
                    <!--</a>-->
                  <!--</p>-->
                  <!--<p>-->
                    <!--<b>Full Functionality</b>-->
                  <!--</p>-->
                  <!--<p>-->
                    <!--You have access to:-->
                    <!--<br>-->
                    <!--1. Warehouses in Ukraine, Belarus and USA to keep items-->
                    <!--<br>-->
                    <!--2. Express shipping-->
                    <!--<br>-->
                    <!--3. Ability to create your lable and send your items separately-->
                    <!--<br>-->
                    <!--4. Dedicated support team-->
                    <!--<br>-->
                    <!--<a href="#">-->
                      <!--Learn More-->
                    <!--</a>-->
                  <!--</p>-->

                  <!--<a v-close-popover class="tooltip__close"></a>-->
                <!--</template>-->
              <!--</v-popover>-->


            <!--</div>-->

            <!--<div class="registration-form__input">-->
              <!--<LargeSwitch-->
                <!--:activeValue="constValue.FUNCTIONALITY.FULL"-->
                <!--:inactiveValue="constValue.FUNCTIONALITY.EASY"-->
                <!--:labelActiveValue="'Full'"-->
                <!--:labelInactiveValue="'Simplified'"-->
                <!--:name="'radioFunctionality'"-->
                <!--@change="changeFunctionality"-->
                <!--v-model="checkedFunctionality"-->
              <!--/>-->
            <!--</div>-->
          <!--</div>-->
        </div>
        <div class="registration-form__agree mt-3">

          <label class="d-flex" style="font-size: 14px; flex-wrap: wrap;">
            <DefaultCheckbox
                v-bind:class="{'ui-no-valid': validation.registrationAgree}"
                :error="validation.registrationAgree"
                :errorTxt="$t(`error_required.localization_value.value`)"
                :label="''"
                v-model="registrationAgree"
            />
            {{$t('registration_agree.localization_value.value')}} <a class="table-link btn-style ml-1" :href="`https://skladusa.com/${$store.getters.GET_LANG}/user-agreement/`" target="_blank">{{$t('registration_TermsConditions.localization_value.value')}}</a> <span class="ml-1">{{$t('registration_and.localization_value.value')}}</span>
            <a class="table-link btn-style ml-1" :href="`https://skladusa.com/${$store.getters.GET_LANG}/privacy-policy/`" target="_blank">{{$t('registration_PrivacyPolicy.localization_value.value')}}</a> <span class="ml-1">{{$t('registration_ofSkladUSA.localization_value.value')}}</span>
          </label>
        </div>
      </div>


      <div class="registration-form__section-bottom">
        <div class="registration-form__section-bottom-link">
          <router-link class="registration-form__section-bottom-link-i"
                       :to="$store.getters.GET_PATHS.login"
          >
            {{ $t('registration_haveAccount.localization_value.value') }}
          </router-link>
        </div>
        <div class="registration-form__section-bottom-btn">
          <MainButton
              v-bind:class="{'disabled-btn' : $store.getters.getRegistrationLoading}"
              class="registration-form__section-bottom-btn-i btn-fit-content"
              :value="$t('registration_createAccount.localization_value.value')"
              v-on:click.native="registration"
          />
        </div>
      </div>
      <!--<template v-if="errorForm">-->
        <!--<div class="form-errors"  v-for="(item, index) in errorForm" :key="index">-->
          <!--{{item[0]}}-->
        <!--</div>-->
      <!--</template>-->


    </div>
  </div>
</template>

<script>
import Language from '../../components/Langueage/Language.vue'
import Fb from '../../../../../../public/img/login-group/fb.svg?inline'
import DefaultInput from '../../../../UI/inputs/DefaultInput/DefaultInput.vue'
import DefaultCheckbox from '../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox.vue'
// import LargeSwitch from '../../../../UI/radiobuttons/LargeSwitch/LargeSwitch.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import DefaultSelect from '../../../../UI/selectiones/DefaultSelect/DefaultSelect.vue'

import VFacebookLogin from 'vue-facebook-login-component'

// import { VTooltip, VPopover  } from 'v-tooltip'
import {validation} from "../../../../../services/validation"
import TooltipBtn from "../../../../UI/tooltips/TooltipBtn/TooltipBtn.vue"

import {filterCountries} from '../../../../../services/filterCountries'
import PasswordRequirements from "@/components/coreComponents/PasswordRequirements/PasswordRequirements";



export default {
  name: "SignUpForm",

  components:{
    PasswordRequirements,
    Language,
    Fb,
    DefaultInput,
    DefaultCheckbox,
    // LargeSwitch,
    MainButton,
    DefaultSelect,
    // VTooltip,
    // VPopover,
    TooltipBtn,
    VFacebookLogin,
  },

  data(){

    return{

      constValue: {
        AMAZON: 'amazon',
        MERCHANT: 'merchant',
        FUNCTIONALITY: {
          FULL: 'full',
          EASY: 'easy',
        },
      },

      visibleValidation: false,

      fromSNG: false,

      successForm: false,
      errorForm: false,
      serverError: false,

      useFB: false,
      FB: {},
      model: {},
      scope: {},
      fbId: '',

      name: '',
      surName: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      country: '',
      postNumber: '',
      loginShop : '',
      itn : '',
      createPassword: '',
      checkedAmazon: false,
      checkedMerchant: false,
      checkedFunctionality: 'full',
      registrationAgree: false,
      countrySelect: '',
      countrySelectModel: '',
      selectedCountry: '',
      stateDistrict: '',
      tooltipFunctionalityActive: true,
      referral: '',

      validation: {
        name: false,
        surName:false,
        phone:false,
        email: false,
        createPassword: false,
        address:false,
        city:false,
        selectedCountry: false,
        registrationAgree: false,
        stateDistrict: false,
        postNumber: false,
        loginShop: false,
        itn: false,
      },

      validationTranslate: {
        name: 'default',
        surName:'default',
        phone:'default',
        email: 'default',
        createPassword: 'default',
        address:'default',
        city:'default',
        selectedCountry: 'default',
        registrationAgree: 'default',
        stateDistrict: 'default',
        postNumber: 'default',
        loginShop: 'default',
        itn: 'default',
      },

      validationTxt: {
        name: false,
        surName:false,
        phone:false,
        email: false,
        createPassword: false,
        address:false,
        city:false,
        selectedCountry: false,
        registrationAgree: false,
        stateDistrict: false,
        postNumber: false,
        loginShop: false,
        itn: false,
      },

      customValidation: {
        fullFilment: false,
      },

      options: [{}],

      optionsDistrict: [{}],
    }
  },

  mounted() {
    this.$store.dispatch('fetchCountries').then(() => {
      let countries = this._.toArray(this.$store.getters.getCountries);
      let filteredCountries = filterCountries(countries)

      this.options = filteredCountries.countries;
      this.selectedCountry = filteredCountries.country.ua.item;

      this.$store.dispatch('getRegionsByCountry', filteredCountries.country.ua.item.id).then(() => {
        this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
      }).catch(error => this.createErrorLog(error));
    })

    //********  CHECK REFERRAL LINK    ********//
    if(this.$route.query.ref !== undefined) {
      let data = {
        code: this.$route.query.ref
      }
      this.$store.dispatch('checkReferral', data).then((response) => {
        if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
          this.referral = data.code
        }
      });
    }

  },

  methods: {
    changeCountry(val) {
      if(val === '') return this.optionsDistrict = ''
      this.selectedCountry = val;

      if(val?.country_group?.fromSNGGroup){
        this.fromSNG = true
      } else {
        this.fromSNG = false
      }

      this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
        this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
        this.stateDistrict = ''
      });
    },

    changeDistrict(val) {
      this.stateDistrict = val;
    },

    changeFunctionality(val){
      this.checkedFunctionality = val;
    },

    registration() {
      this.visibleValidation = true

      let validationItems = {
        name: this.name,
        surName: this.surName,
        phone: this.phone,
        email: this.email,
        createPassword: this.createPassword,
        address: this.address,
        city: this.city,
        selectedCountry: this.selectedCountry,
        registrationAgree: this.registrationAgree,
        stateDistrict: this.stateDistrict,
        postNumber: this.postNumber,
        loginShop: this.loginShop,
      }


      let validationOptions = {
        name: {type: ['latin', 'empty',]},
        surName: {type: ['latin', 'empty',]},
        phone: {type: ['phone', 'empty',]},
        email: {type: ['email', 'latin', 'empty',]},
        createPassword: {type: ['latin', 'password', 'empty',]},
        address: {type: ['latin', 'empty',]},
        city: {type: ['latin', 'empty',]},
        selectedCountry: {type: ['empty',]},
        registrationAgree: {type: ['checked']},
        stateDistrict: {type: ['empty',]},
        postNumber: {type: ['empty',]},
        // loginShop: {type: ['latin', 'empty',]},
      };

      if(this.fromSNG){
        validationItems.itn = this.itn
        validationOptions.itn = {type: ['empty']}
      }

      let validate = validation(validationItems, validationOptions);
      console.log(validate);
      this.validation = validate.validation;
      this.validationTranslate = validate.validationError;

      if(!this.checkedAmazon && !this.checkedMerchant){
        this.customValidation.fullFilment = true;
      } else {
        this.customValidation.fullFilment = false;
      }

      if(validate.isValidate && !this.customValidation.fullFilment) {
        this.serverError = false;

        let data = {
          'name': this.name,
          'last_name': this.surName,
          'phone': this.phone,
          'email': this.email,
          'password': this.createPassword,
          'address': this.address,
          'city': this.city,
          'country_id': this.selectedCountry.id,
          'country_region_id': this.stateDistrict.id,
          'zip': this.postNumber,
          'login': this.loginShop,
          'itn_number': this.itn,
          'fulfilment_by_amazon': this.checkedAmazon ? 1 : 0,
          'fulfilment_by_merchant': this.checkedMerchant ? 1 : 0,
          'terms': 1,
          'language_id': this.$store.getters.GET_LANGUAGES.find(item => {
            return item.locale === localStorage.getItem('translationLang')
          })?.id || 1,
        }

        if(this.referral !== ''){
          data.referral = this.referral
        }

        if(this.fbId !== ''){
          data.facebook_id = this.fbId
        }

        this.$store.dispatch('registration', data).then((response) => {

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$emit('successRegister');
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

          // if(this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
          //   this.$emit('successRegister');
          // } else if(response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          //   let errors = response.response.data.errors
          //
          //   this.serverError = true
          //
          //   errors.name ?          ( this.validation.name = true,            this.validationTxt.name = errors.name[0] )                  : false;
          //   errors.last_name ?     ( this.validation.surName = true,         this.validationTxt.surName = errors.last_name[0] )          : false;
          //   errors.phone ?         ( this.validation.phone = true,           this.validationTxt.phone = errors.phone[0] )                : false;
          //   errors.email ?         ( this.validation.email = true,           this.validationTxt.email = errors.email[0] )                : false;
          //   errors.password ?      ( this.validation.createPassword = true,  this.validationTxt.createPassword = errors.password[0] )    : false;
          //   errors.address ?       ( this.validation.address = true,         this.validationTxt.address = errors.address[0] )            : false;
          //   errors.city ?          ( this.validation.city = true,            this.validationTxt.city = errors.city[0] )                  : false;
          //   errors.country_id ?    ( this.validation.selectedCountry = true, this.validationTxt.selectedCountry = errors.country_id[0] ) : false;
          //   errors.company_name ?  ( this.validation.loginShop = true,       this.validationTxt.loginShop = errors.company_name[0])      : false;
          //   errors.itn_number ?    ( this.validation.itn = true,             this.validationTxt.itn = errors.itn_number[0])              : false;
          //   errors.zip ?           ( this.validation.postNumber = true,      this.validationTxt.postNumber = errors.zip[0])              : false;
          //
          //   // this.errorForm = _.toArray(response.response.data.data.errors);
          // }

        });

      }

    },

    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },

    loginFb() {
      this.getUserData()
    },

    getUserData() {
      this.FB.api(
          '/me',
          { fields: 'id, name, picture, email' },
          (user) => {
            this.useFB = true;
            this.name = user.name.split(' ')[0];
            this.surName = user.name.split(' ')[1];
            this.email = user.email;
            this.fbId = user.id
          }
      )
    },

  }
}

</script>

<style lang="scss">
  @import "./SignUpForm";
</style>
