<template>
  <Modal
          @close="$emit('close')"
          class="address-editing-modal statistic-popup"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_CreateRAMP'])"></div>
        {{$t(`common_CreateRAMP.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="address-editing__content">
        <div class="section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_CreateRAMPField'])"></div>
          {{$t(`common_CreateRAMPField.localization_value.value`)}}
        </div>

        <div class="address-editing__row custom-row"
             v-for="(card, index) in cards"
             :key="index"
        >
          <div class="address-editing__col custom-col mb-0">
            <div class="order-create__title-horizontal"
                 :key="index + '1'"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
              'common_CreateRAMPCard',
              'common_DeleteCard',
            ])"></div>
              <TitleHorizontal
                  :value="$t('common_CreateRAMPCard.localization_value.value') + ' #' + (Number(index) + 1)"
                  :rightBtn="true"
                  :rightBtnType="'delete'"
                  :rightBtnText="$t('common_DeleteCard.localization_value.value')"
                  @rightBtnClick="removeCard(index)"
              />
            </div>
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_CreateRAMPEmail'])"></div>
            <DefaultInput
                    :label="$t('common_CreateRAMPEmail.localization_value.value')"
                    v-bind:class="{'ui-no-valid': card.validation.personalEmail}"
                    :error="card.validation.personalEmail"
                    :errorTxt="$t(`${card.validationTranslate.personalEmail}.localization_value.value`)"
                    v-model="card.information.personalEmail"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_CreateRAMPFirstName'])"></div>
            <DefaultInput
                    :label="$t('common_CreateRAMPFirstName.localization_value.value')"
                    v-bind:class="{'ui-no-valid': card.validation.personalName}"
                    :error="card.validation.personalName"
                    :errorTxt="$t(`${card.validationTranslate.personalName}.localization_value.value`)"
                    v-model="card.information.personalName"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_CreateRAMPLastName'])"></div>
            <DefaultInput
                    :label="$t('common_CreateRAMPLastName.localization_value.value')"
                    v-bind:class="{'ui-no-valid': card.validation.personalCompany}"
                    :error="card.validation.personalCompany"
                    :errorTxt="$t(`${card.validationTranslate.personalCompany}.localization_value.value`)"
                    v-model="card.information.personalCompany"
            />
          </div>
        </div>


        <div class="address-editing__row custom-row">
          <div class="address-editing__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_CreateRAMPAddCard'])"></div>
            <MainButton
                :value="$t('common_CreateRAMPAddCard.localization_value.value')"
                class="address-editing__btn-i"
                @click.native="addCard"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_update'])"></div>
        <span class="site-link site-link--alt address-editing__btn-i mr-3"
              @click="$emit('close')">
                {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                :value="$t('common_create.localization_value.value')"
                class="address-editing__btn-i"
                @click.native="sendRAMPCard"
                v-bind:class="{'disabled-btn' : $store.getters.getAccountBtn}"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
  import DefaultInput from "../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "../../../UI/buttons/MainButton/MainButton";
  import Modal from "../../../commonModals/Modal";
  import {PersonalInformation} from "@/components/globalModels/PersonalInformation";
  import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";

  export default {
    name: "CreateRAMPPopup",

    components: {
      TitleHorizontal,
      Modal,
      DefaultInput,
      MainButton,
    },

    props: {

    },

    data() {
      return {
        cards: [new PersonalInformation()],
      }
    },

    methods: {
      addCard() {
        this.cards.push(new PersonalInformation())
      },

      removeCard(index) {
        this.cards.splice(index, 1)
      },

      sendRAMPCard() {
        let valid = true
        let prepareData = {cards: []}

        this.cards.map(card => {
          if(!card.personalInfoPartsValidate({
            personalName: true,
            personalPhone: false,
            personalCompany: true,
            personalEmail: true,
            phoneEmpty: false,
            phoneNumber: false,
            phoneOptional: false,
            emailOptional: true,
            nameNoLatin: false,
          })) {
            valid = false
          }

          /**
           * prepare data
           */
          let personalInformationData = card.preparePersonalInformation()
          let data = {
            email: personalInformationData.personalEmail,
            first_name: personalInformationData.personalName,
            last_name: personalInformationData.personalCompany,
          }
          prepareData.cards.push(data)
        })

        if(!valid) {
          return
        }

        this.$store.dispatch('addRAMPCards', prepareData).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$emit('close')
              this.openNotify('success', 'common_notificationRampCardRequested')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        }).catch(error => this.createErrorLog(error))

      },
    }
  }
</script>

<style scoped>

</style>
