<template>
  <div class="horizontal-warning">
    <div class="horizontal-warning__content">
      <div class="horizontal-warning__ico"
           v-if="ico"
           v-bind:class="{
           'horizontal-warning__ico--warning' : !icoType,
           'horizontal-warning__ico--orange-warning' : icoType === 'orange-warning',
           }"
      >
      </div>
      <div class="horizontal-warning__text"
           v-bind:class="{
           'horizontal-warning__text--black-text': textType === 'black',
           'horizontal-warning__text--full-width': fullWidth === true,
           }"
      >
        {{text}}
        <slot name="text">

        </slot>
        <div class="horizontal-warning__tooltip" v-if="tooltip">
          <slot name="tooltip">

          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "HorizontalWarning",

    props: {
      ico: {
        type: [Boolean],
        default: false
      },
      text: {
        type: [String, Number],
        default: ''
      },
      tooltip: {
        type: [Boolean],
        default: false
      },
      icoType: {
        type: [String],
        default: null
      },
      textType: {
        type: [String],
        default: null
      },
      fullWidth: {
        type: [Boolean],
        default: false
      },
    }

  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .horizontal-warning{
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;

    &__content{
      display: flex;
      padding: 16px 24px;
      background: white;
      border-radius: 5px;
    }

    &__ico{
      width: 21px;
      min-width: 21px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 13px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      &--warning{
        background-image: url("../../../assets/img/common/warning-icon.svg");
      }

      &--orange-warning{
        width: 27px;
        height: 25px;
        background-image: url("../../../assets/img/common/orange-warning-icon.svg");

        + .horizontal-warning__text{
          margin-top: 2px;
        }
      }

    }
    &__text{
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      line-height:  21px;
      color: $brown;

      &--black-text{
        color: $black;
      }

      &--full-width {
        width: 100%;
      }
    }
    &__tooltip{
      display: inline-block;
    }
  }
</style>
