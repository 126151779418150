<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup shipping-company-popup"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['dashboard_UPCCodesImport',])"></div>
        {{$t('dashboard_UPCCodesImport.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="shipping-company-popup__body">
          <div class="shipping-company-popup__content">
            <div class="custom-row">
              <div class="custom-col mb-3">
                <DropZone class="drop-zone-bg"
                          :parentFiles="files"
                          :maxSize="'5'"
                          :multiple="false"
                          :accept="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                          @changeImg="changeImg"
                />
              </div>

            </div>
          </div>

        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['dashboard_UPSImport',])"></div>
        <MainButton
            class="custom-popup__btn-i "
            v-bind:class="{'disabled-btn' : $store.getters.getUPCCodesBtn}"
            :value="$t('dashboard_UPSImport.localization_value.value')"
            @click.native="uploadFile"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DropZone from "../../../../coreComponents/DropZone/DropZone";

  export default {
    name: "UPCCodesImport",
    components: {
      Modal,
      MainButton,
      DropZone,
    },

    props: [
      'title'
    ],

    data() {
      return {
        files: [],
      }
    },

    methods: {
      changeImg(files) {
        this.files = files
      },

      uploadFile() {
        let reader = new FormData();
        reader.append("import-file", this._.first(this.files).file)

        this.$store.dispatch('uploadUPSCodesFile', reader).then((response) => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationStatusChanged')
              this.$emit('reloadCodes')
              this.$emit('close')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })
      }
    }
  }
</script>

<style scoped lang="scss">

  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .shipping-company-popup{

    &__body{
      display: flex;

      @include for-680{
        flex-direction: column;
      }
    }

    &__content{
      width: 100%;

      @include from-680 {
        padding-right: 20px;
      }
    }

    &__file{
      max-width: 105px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      @include for-680{
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin-bottom: 35px;
      }

      input[type="file"]{
        display: none;
      }
    }

    &__file-label{
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &__file-img{
      display: flex;
      flex-direction: column;

      img{
        width: 105px;
        height: 105px;
        object-fit: contain;
      }
    }

    &__file-text{
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $brown;
    }

  }

  .custom-popup__btn{

  }

  .custom-popup__btn-i{
    margin-right: 10px;
    min-width: 122px;
    width: fit-content;
    margin-bottom: 10px;

    &.secondary{
      border-width: 2px;
    }
  }
</style>
