import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: "Notifications",
  components: {
    vueCustomScrollbar,
  },

  data(){
    return{
      settings: {
        suppressScrollY: true,
        suppressScrollX: false,
        wheelPropagation: false,
        useBothWheelAxes: true,
        wheelSpeed: 0.5,
        swipeEasing: true,
        scrollXMarginOffset: 16,
      },


      // notifications: [
      //   {
      //     title: 'Proform Creation Rules Proform Creation RulesProform Creation RulesProform Creation RulesProform Creation RulesProform Creation RulesProform Creation Rules',
      //     text: 'We remind the rules of proform creation, which are extremely important for successful order shipping. 1. Save description order which means from the ',
      //   },
      //   {
      //     title: 'Orders From Amazon',
      //     text: 'Amazon revetly tightened the rules for using and presenting use data, which led to orders entering the system without a buyer&39’s address. The SkladUSA ',
      //   },
      //   {
      //     title: 'Added a New Feature',
      //     text: 'From now on, if your ETSY store is connected to our system via API, when creating an order from  PAyPal transactions, if the recepient&s ETSU amd transaction',
      //   },
      //   {
      //     title: 'Goods Relocation Function',
      //     text: 'We would like to note that when creating an FBM order from a warehouse in the ISA, if you add a product that is not available the quantity of goods is indicate',
      //   },
      //   {
      //     title: 'Goods Relocation Function',
      //     text: 'We would like to note that when creating an FBM order from a warehouse in the ISA, if you add a product that is not available the quantity of goods is indicate different things and more and more text',
      //   },
      // ]
    }
  },

  created() {
    this.$store.dispatch('fetchNotificationsNoRead')
  },

  methods: {
    viewNotification(item) {
      this.notificationPopup(item.id)
    }
  }
}
