<template>
  <div class="dashboard-warning-block">
    <div class="dashboard-warning-item">
      <HorizontalWarning
              class="horizontal-warning-scoped"
              :ico="true"
              :icoType="'orange-warning'"
              :textType="'black'"
              :fullWidth="true"
      >
        <template slot="text">
          <div
              class="dashboard-warning-item-wrap"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
                'dashboard_activateGoogleAuth',
                'dashboard_activateGoogleAuthText',
                'dashboard_googleAuthAccountSettings',
                'dashboard_activateGoogleAuthText2',
                ])"></span>
            <span>
              {{$t('dashboard_activateGoogleAuthText.localization_value.value')}}
              <span @click="goToProfile" class="site-link">
                  {{$t('dashboard_googleAuthAccountSettings.localization_value.value')}}
              </span>
              {{$t('dashboard_activateGoogleAuthText2.localization_value.value')}}!
            </span>

            <span class="dashboard-warning-checkbox">
              <SwitchCheckbox
                  :label="$t('dashboard_activateGoogleAuth.localization_value.value')"
                  :widerGap="true"
                  :value="false"
                  :disabled="true"
                  :noHover="true"
              />
            </span>
          </div>
        </template>
      </HorizontalWarning>
    </div>
  </div>

</template>

<script>
  import HorizontalWarning from "../../../../coreComponents/HorizontalWarning/HorizontalWarning";
  import SwitchCheckbox from "../../../../UI/checkboxes/SwitchCheckbox/SwitchCheckbox";
  import {checkAccess} from "../../../../../mixins/userCredentionalMixins/userCredentional";

  export default {
    name: "DashboardGoogleAuthWarning",
    components: {SwitchCheckbox, HorizontalWarning},
    mixins: [checkAccess],

    methods: {

    }
  }
</script>

<style scoped>

  .horizontal-warning-scoped{
    margin-top: 0;
    margin-bottom: 0;
  }

  .dashboard-warning-block{
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .dashboard-warning-checkbox {
    font-weight: normal;
    flex-shrink: 0;
  }

  .dashboard-warning-item-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    .dashboard-warning-item-wrap {
      display: block;
    }
  }
</style>
