<template>
  <div class="fragment">
    <div class="dashboard-row">
      <div class="dashboard-col mt--8px">
        <SearchEngine class="p-0 m-0"/>
      </div>

<!--      <div class="dashboard-col">-->
<!--        <CalculateShipping-->
<!--            :list="list"-->
<!--            @changeInfoCardsList="changeInfoCardsList"-->
<!--        />-->
<!--      </div>-->

      <div class="dashboard-col">
        <CalculateShipping
            :list="draggableList"
            @changeInfoCardsList="changeInfoCardsList"
        />
      </div>

      <div class="dashboard-col">
        <div class="dashboard-row">
          <div v-if="_.has(currentPermissions, PERMISSIONS.CLAIM_GENERAL)" class="dashboard-col dashboard-col--problem-parcel">
            <ProblemParcel
            />
          </div>
          <div class="dashboard-col dashboard-col--lg-100 mb-0"
               :class="{
            'dashboard-col--80': _.has(currentPermissions, PERMISSIONS.CLAIM_GENERAL),
               }"
          >
            <div class="dashboard-row">
              <div class="dashboard-col"
                   v-if="$store.getters.getNotificationsNoRead !== 'undefined' && $store.getters.getNotificationsNoRead.length > 0"
              >
                <Notifications class="notification-admin"/>
              </div>

              <div class="dashboard-col">
                <div class="custom-row w-auto">
                  <div class="custom-col custom-col--33 custom-col--md-50 custom-col--xs-100">
                    <UPSCode/>
                  </div>

                  <div class="custom-col custom-col--33 custom-col--md-50 custom-col--xs-100">
                    <ExportRequests/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ThankYouPopup
        v-if="thankYouPopupPopup"
        @close="changeThankYouPopupPopup(false)"
        :title="'common_paySuccessTitle'"
        :text="'common_paySuccessText'"
        :linkLastText="'common_paySuccessTextLink'"
        :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments"
    />

  </div>
</template>

<script>
import CalculateShipping from "../CalculateShipping/CalculateShipping.vue";
import Notifications from "../Notifications/Notifications.vue";
import ThankYouPopup from "../../../../coreComponents/Popups/ThankYouPopup/ThankYouPopup.vue";
import ProblemParcel from "../ProblemParcel/ProblemParcel";
import UPSCode from "../UPSCode/UPSCode";
import SearchEngine from "../../../../coreComponents/SearchEngine/SearchEngine";
import {DASHBOARD_DRAGGABLE, RETURN_GOODS_TYPES} from "@/staticData/staticVariables";
import ExportRequests from "@/components/modules/DashboardModule/components/ExportRequests/ExportRequests";

export default {
  name: "DashboardAdmin",
  components: {
    ExportRequests,
    CalculateShipping,
    Notifications,
    ThankYouPopup,
    ProblemParcel,
    UPSCode,
    SearchEngine,
  },

  data(){
    return{
      thankYouPopupPopup: false,
      isModalUPCCodesImport: false,
      list: [],
      draggableList: [
        // {
        //   firstElement: true,
        //   firstElementText: this.$t('dashboard_calculateShipping.localization_value.value'),
        // },
      ],
      userBalance: 0,
    }
  },

  created() {
    // this.list = [
    //   {
    //     firstElement: true,
    //     firstElementText: this.$t('dashboard_calculateShipping.localization_value.value'),
    //   },
    //   {
    //     ico: '/img/dashboard-group/balance-icon.svg',
    //     bigText: '$' + this.userBalance,
    //     textFromLink: 'My Balance',
    //     textFromLinkHref: this.$store.getters.GET_PATHS.balanceHistory,
    //     // text: 'My Balance',
    //   },
    //   {
    //     ico: '/img/dashboard-group/returned-packages-icon.svg',
    //     bigText: '$200',
    //     text: 'Returned Packages',
    //   },
    //   {
    //     ico: '/img/dashboard-group/unidentified-packages-icon.svg',
    //     bigText: '20',
    //     text: 'Unidentified Packages',
    //   },
    //   {
    //     ico: '/img/dashboard-group/invoices-icon.svg',
    //     bigText: '$594',
    //     text: 'Invoices',
    //   },
    //   {
    //     ico: '/img/dashboard-group/shops-icon.svg',
    //     bigText: '5',
    //     text: 'Shops',
    //     linkText: 'Connect New',
    //     linkPath: ''
    //   },
    //   {
    //     ico: '/img/dashboard-group/listings-icon.svg',
    //     bigText: '40',
    //     links: [
    //       {
    //         text: 'Listings',
    //         linkText: 'Create New',
    //         linkPath: this.$store.getters.GET_PATHS.financeUkrTreasuresLinkCreate
    //       },
    //     ],
    //   },
    //   {
    //     ico: '/img/dashboard-group/ship-to-amazon-icon.png',
    //     bigText: '10',
    //     text: 'Ship to Amazon',
    //   },
    //   {
    //     ico: '/img/dashboard-group/fba-invoices-icon.svg',
    //     bigText: '54',
    //     text: 'FBA Invoices',
    //   },
    //   {
    //     ico: '/img/dashboard-group/paypal-transactions-icon.png',
    //     bigText: '$340',
    //     text: 'PayPal Transactions',
    //   },
    //   {
    //     ico: '/img/dashboard-group/refund-requests-icon.svg',
    //     bigText: '$277.03',
    //     text: 'Refund Requests (9)',
    //   },
    //   {
    //     ico: '/img/dashboard-group/orders-from-returned-icon.svg',
    //     bigText: '345',
    //     text: 'Orders from returned',
    //   },
    //   {
    //     ico: '/img/dashboard-group/users-requests-icon.svg',
    //     bigText: '12',
    //     text: 'Users’ Requests',
    //   },
    //   {
    //     ico: '/img/dashboard-group/delivery-statistics-icon.svg',
    //     bigText: 'Delivery statistics',
    //     fillColor: true,
    //   },
    // ]
  },

  mounted(){

    this.$store.dispatch('getDashboardDraggableBlockData').then(response => {

      let draggableData = this.getRespData(response)

      draggableData.map(draggableItem => {
        switch (draggableItem.name) {
          case DASHBOARD_DRAGGABLE.BALANCE:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.PAYMENT_HISTORY_GENERAL)) return

            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.balanceHistory,
              firstElementText: this.$t('dashboard_balance.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.PAYONEER_BALANCE:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER)) return

            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.balancePayoneerHistory,
              firstElementText: this.$t('dashboard_balancePayoneer.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.RETURN_PARCELS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.RETURN_PARCEL_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/returned-packages-icon.svg',
              bigText: draggableItem.values?.count,
              linkText: this.$t('dashboard_returnedPackages.localization_value.value'),
              linkTextTranslate: 'dashboard_returnedPackages',
              linkPath: this.$store.getters.GET_PATHS.problemsProcessedReturnsGoods + '/' + RETURN_GOODS_TYPES.PROCESSED_RETURNS,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.RETURN_PARCEL_UNDEFINED:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.RETURN_PARCEL_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/unidentified-packages-icon.svg',
              bigText: draggableItem.values?.count,
              linkText: this.$t('dashboard_unidentifiedPackages.localization_value.value'),
              linkTextTranslate: 'dashboard_unidentifiedPackages',
              linkPath: this.$store.getters.GET_PATHS.problemsUnidentifiedReturns,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.RETURN_PARCEL_ORDERS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.RETURN_PARCEL_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/fba-invoices-icon.svg',
              bigText: draggableItem.values?.count,
              linkText: this.$t('dashboard_ordersCreatedFromReturned.localization_value.value'),
              linkTextTranslate: 'dashboard_ordersCreatedFromReturned',
              linkPath: this.$store.getters.GET_PATHS.ordersFBM + '?returnGoods=1&paid=-1',
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.RETURN_PARCEL_REQUESTS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.RETURN_PARCEL_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/package-icon.svg',
              icoStyle: 'max-width: 30px;',
              bigText: draggableItem.values?.count,
              linkText: this.$t('dashboard_userReturnRequests.localization_value.value'),
              linkTextTranslate: 'dashboard_userReturnRequests',
              linkPath: this.$store.getters.GET_PATHS.problemsRequestReturnsGoods + '?fromUndefinedReturn=1',
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.INVOICES:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/invoices-icon.svg',
              bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
              linkText: this.$t('dashboard_Invoices.localization_value.value'),
              linkTextTranslate: 'dashboard_Invoices',
              linkPath: this.$store.getters.GET_PATHS.financeInvoices,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.SHOPS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.SHOPS_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/shops-icon.svg',
              bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
              text: this.$t('dashboard_Shops.localization_value.value'),
              textTranslate: 'dashboard_Shops',
              linkText: this.$t('dashboard_connectNew.localization_value.value'),
              linkTextTranslate: 'dashboard_connectNew',
              linkPath: this.$store.getters.GET_PATHS.mainSettingsShopsCreate,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          // case DASHBOARD_DRAGGABLE.LISTINGS:
          //   if (!this._.has(this.currentPermissions, this.PERMISSIONS.PAYMENT_LISTING_GENERAL)) return
          //   this.draggableList.push({
          //     ico: '/img/dashboard-group/listings-icon.svg',
          //     links: [
          //       {
          //         text: this.$t('dashboard_listings.localization_value.value'),
          //         textTranslate: 'dashboard_listings',
          //         linkText: this.$t('dashboard_createNew.localization_value.value'),
          //         linkTextTranslate: 'dashboard_createNew',
          //         linkPath: this.$store.getters.GET_PATHS.financeUkrTreasuresLinkCreate
          //       },
          //       // {
          //       //   text: this.$t('dashboard_Etsy.localization_value.value'),
          //       //   textTranslate: 'dashboard_Etsy',
          //       //   linkText: this.$t('dashboard_CreateEtsy.localization_value.value'),
          //       //   linkTextTranslate: 'dashboard_CreateEtsy',
          //       //   useFunction: this.getEtsyListing
          //       // },
          //     ],
          //     sort: draggableItem.sort,
          //     dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
          //   })
          //   break
          case DASHBOARD_DRAGGABLE.SHIP_AMAZON:
            this.draggableList.push({
              ico: '/img/dashboard-group/ship-to-amazon-icon.png',
              bigText: '-',
              text: this.$t('dashboard_shipToAmazon.localization_value.value'),
              textTranslate: 'dashboard_shipToAmazon',
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.FBA_INVOICES:
            this.draggableList.push({
              ico: '/img/dashboard-group/fba-invoices-icon.svg',
              bigText: '-',
              text: this.$t('dashboard_fBAInvoices.localization_value.value'),
              textTranslate: 'dashboard_fBAInvoices',
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.PAYPAL_TRANSACTIONS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/paypal-transactions-icon.png',
              bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
              linkText: this.$t('dashboard_payPalTransactions.localization_value.value'),
              linkTextTranslate: 'dashboard_payPalTransactions',
              linkPath: this.$store.getters.GET_PATHS.cusPayments,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.CALCULATE_SHIPPING:
            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.calculator,
              firstElementText: this.$t('dashboard_calculateShipping.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.CALCULATE_ENGRAVING_SHIPPING:
            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.calculatorEngraving,
              firstElementText: this.$t('dashboard_calculateEngravingShipping.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.DELIVERY_STATISTIC:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.DELIVERY_STATISTIC_GENERAL)) return

            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.statisticsDeliveryStatistics,
              firstElementText: this.$t('dashboard_deliveryStatistics.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.SENT_SMS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.SENT_SMS_GENERAL)) return

            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.chat,
              firstElementText: this.$t('dashboard_smsChat.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.STORAGE_WITHOUT_SHIPPING:
            this.draggableList.push({
              ico: '/img/dashboard-group/invoices-icon.svg',
              bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
              linkText: this.$t('menu_inactiveFBMUsers.localization_value.value'),
              linkTextTranslate: 'menu_inactiveFBMUsers',
              linkPath: this.$store.getters.GET_PATHS.inactiveFBMUsers,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
        }
      })
    }).catch(error => this.createErrorLog(error))

    // if(!Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'balance')) {
    //   let interval = setInterval(() => {
    //     if(Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'balance')){
    //       this.list[1].bigText = '$' + this.$store.getters.getUserProfile.balance
    //       clearInterval(interval)
    //     }
    //   },50)
    // } else {
    //   this.list[1].bigText = '$' + this.$store.getters.getUserProfile.balance
    // }
  },

  methods: {

    changeThankYouPopupPopup(val) {
      this.thankYouPopupPopup = val
    },

    changeUPCCodesImport(val){
      this.isModalUPCCodesImport = val
    },

    changeInfoCardsList(list) {
      let data = []
      list.map((listItem, index) => {
        // if(listItem?.firstElement) return
        data.push({
          "dashboard_summary_item_id" : listItem.dashboardSummaryItemId,
          "sort" : index
        })
      })
      this.$store.dispatch('changeSortDashboardDraggableBlockData', data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
      this.draggableList = list
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";
  @import "../../../../../scss/colors";


  .dashboard-row{
    margin: 0 -12px;
  }
  .dashboard-col{
    padding: 0 12px;

    &--problem-parcel{
      width: 100%;
      max-width: 20%;

      @include for-1400{
        max-width: 320px;
        width: 100%;
        order: 2;
      }

      @include for-550{
        max-width: 100%;
      }
    }

    //@include for-1400{
    //  &:nth-child(1){
    //    order: 1;
    //  }
    //  &:nth-child(2){
    //    order: 3;
    //  }
    //  &:nth-child(3),
    //  &:nth-child(4){
    //    order: 2;
    //  }
    //  &:nth-child(5){
    //    order: 4;
    //  }
    //  &:nth-child(6){
    //    order: 6;
    //  }
    //}
  }

  .mt--8px{
    margin-top: -8px;
  }
</style>
