<template>
  <modal
          @close="$emit('close')"
          class="custom-popup-modal custom-popup shipping-company-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['dashboard_ImportEtsyListings'])"></div>
        {{ $t('dashboard_ImportEtsyListings.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="shipping-company-popup__body">
          <div class="shipping-company-popup__content">
            <div class="custom-row">
              <div class="custom-col mb-3"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['dashboard_EtsyShops'])"></div>
                <DefaultSelect
                        :options="options"
                        :label="$t('dashboard_EtsyShops.localization_value.value')"
                        :optionsLabel="'shop_name'"
                        @change="changeEtsyShops"
                />
              </div>
            </div>
            <div class="custom-row">
              <div class="custom-col mb-5"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['dashboard_OrderNumber'])"></div>
                <DefaultInput
                        :label="$t('dashboard_OrderNumber.localization_value.value')"
                        :type="'text'"
                        v-model="orderNumber"
                />
              </div>
            </div>
          </div>

        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn">
        <MainButton
                class="custom-popup__btn-i "
                :value="'Submit'"
                @click.native="getListings"
                v-bind:class="{'disabled-btn' : Object.keys(etsyShop).length === 0 || orderNumber === ''}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {
    DEFAULT_SHOP_TYPES,
    SHOPS_ADMIN_TYPES,
    UKRAINIAN_TREASURES_SITE
  } from "../../../../../staticData/staticVariables";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "ImportEtsyListings",
    components: {
      DefaultInput,
      DefaultSelect,
      Modal,
      MainButton,
    },

    props: [
      'title'
    ],

    data() {
      return {
        options: [],
        etsyShop: {},
        orderNumber: '',
      }
    },

    mounted() {
      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')
      myQuery.where('type', DEFAULT_SHOP_TYPES.etsy.id)
      myQuery.where('shopStatus', SHOPS_ADMIN_TYPES.valid)

      url = url + myQuery.limit(100000).page(1).url().split('?')[1]

      this.$store.dispatch('fetchShops', url).then(response => {
        this.options = this.getRespPaginateData(response)
      })
    },

    methods: {
      changeEtsyShops(val) {
        this.etsyShop = val
      },

      getListings() {
        let data = {
          'shop_id': this.etsyShop.id,
          'receipt_id': this.orderNumber
        }
        this.$store.dispatch('getEtsyListings', data).then(response => {
          if(!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              let respData = this.getRespData(response)

              window.open(`${UKRAINIAN_TREASURES_SITE}/${respData.slug}`, '_blank');

              this.openNotify('success', 'common_notificationRecordCreated')
              this.$emit('close')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">

  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .shipping-company-popup {

    &__body {
      display: flex;

      @include for-680 {
        flex-direction: column;
      }
    }

    &__content {
      width: 100%;

      @include from-680 {
        padding-right: 20px;
      }
    }

    &__file {
      max-width: 105px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      @include for-680 {
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin-bottom: 35px;
      }

      input[type="file"] {
        display: none;
      }
    }

    &__file-label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &__file-img {
      display: flex;
      flex-direction: column;

      img {
        width: 105px;
        height: 105px;
        object-fit: contain;
      }
    }

    &__file-text {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $brown;
    }

  }

  .custom-popup__btn {

  }

  .custom-popup__btn-i {
    margin-right: 10px;
    min-width: 122px;
    width: fit-content;
    margin-bottom: 10px;

    &.secondary {
      border-width: 2px;
    }
  }
</style>
