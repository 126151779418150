<template>
  <div class="page-content">
    <Breadcrumb
        :hideBreadcrumbs="true"
    />

    <div class="dashboard">

      <DashboardUser
          v-if="$store.getters.getIsAdminRights === 'user'"
      />
      <DashboardAdmin
          v-if="$store.getters.getIsAdminRights === 'admin'"
      />
<!--      <div class="dashboard-row">-->
<!--        <div class="dashboard-col">-->
<!--          <CalculateShipping/>-->
<!--        </div>-->
<!--        <div class="dashboard-col mb-0"-->
<!--             v-if="$store.getters.getIsAdminRights === 'admin'"-->
<!--        >-->
<!--          <div class="dashboard-row">-->
<!--            <div class="dashboard-col dashboard-col&#45;&#45;20 dashboard-col&#45;&#45;lg-50 dashboard-col&#45;&#45;md-100">-->
<!--              <div class="dashboard-case">-->
<!--                <div class="dashboard-case__body">-->
<!--                  <div class="dashboard-case__head">-->
<!--                    UPC statistics-->
<!--                  </div>-->
<!--                  <div class="dashboard-case__content d-flex justify-content-center">-->
<!--                    <MainButton-->
<!--                        class="white-space-line mw-100"-->
<!--                        :value="'UPC codes import'"-->
<!--                        @click.native="changeUPCCodesImport(true)"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="dashboard-col dashboard-col&#45;&#45;60 dashboard-col&#45;&#45;lg-100"-->
<!--        >-->
<!--          <GraphUsualOrders/>-->
<!--        </div>-->
<!--        <div class="dashboard-col dashboard-col&#45;&#45;20 dashboard-col&#45;&#45;lg-50 dashboard-col&#45;&#45;md-100">-->
<!--          <PaymentBlock/>-->
<!--        </div>-->
<!--        <div class="dashboard-col dashboard-col&#45;&#45;20 dashboard-col&#45;&#45;lg-50 dashboard-col&#45;&#45;md-100">-->
<!--          <QuickMoneyBlock/>-->
<!--        </div>-->
<!--        <div class="dashboard-col"-->
<!--             v-if="$store.getters.getNotificationsNoRead !== 'undefined' && $store.getters.getNotificationsNoRead.length > 0"-->
<!--        >-->
<!--          <Notifications/>-->
<!--        </div>-->
<!--        <div class="dashboard-col"-->
<!--        >-->
<!--          <GraphFinanceStatistics/>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <ThankYouPopup
            v-if="thankYouPopupPopup"
            @close="changeThankYouPopupPopup(false)"
            :title="'common_paySuccessTitle'"
            :text="'common_paySuccessText'"
            :linkLastText="'common_paySuccessTextLink'"
            :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments"
    />

<!--    <UPCCodesImport-->
<!--      v-if="isModalUPCCodesImport"-->
<!--      @close="changeUPCCodesImport(false)"-->
<!--    />-->
  </div>
</template>

<script>
  // import {API} from '../../../../src/axios/axiosMainUrl'
  import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";
  import DashboardUser from "./components/DashboardUser/DashboardUser";
  import DashboardAdmin from "./components/DashboardAdmin/DashboardAdmin";
  import ThankYouPopup from "@/components/coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  // import CalculateShipping from "./components/CalculateShipping/CalculateShipping.vue";
  // import GraphUsualOrders from "./components/GraphUsualOrders/GraphUsualOrders.vue";
  // import GraphFinanceStatistics from "./components/GraphFinanceStatistics/GraphFinanceStatistics.vue";
  // import Notifications from "./components/Notifications/Notifications.vue";
  // import PaymentBlock from "./components/PaymentBlock/PaymentBlock.vue";
  // import QuickMoneyBlock from "./components/QuickMoneyBlock/QuickMoneyBlock";
  // import MainButton from "../../UI/buttons/MainButton/MainButton";
  // import UPCCodesImport from "./popups/UPCCodesImport/UPCCodesImport";

  export default {
    name: "Dashboard",
    components: {
      Breadcrumb,
      // CalculateShipping,
      // GraphUsualOrders,
      // Notifications,
      // GraphFinanceStatistics,
      // PaymentBlock,
      // QuickMoneyBlock,
      ThankYouPopup,
      // MainButton,
      // UPCCodesImport,
      DashboardUser,
      DashboardAdmin,
    },

    data(){
      return {
        // isMobile: ''
        thankYouPopupPopup: false,
        // isModalUPCCodesImport: false,
      }
    },

    mounted() {
      // const token = localStorage.getItem('token');
      // API.get('/users', {headers: { Authorization: `Bearer ${token}` }})

      // window.innerWidth < 680 ? this.isMobile = true : this.isMobile = false;

      let paymentId = this.$route.query.paymentId
      let token = this.$route.query.token
      let PayerID = this.$route.query.PayerID

      if(paymentId !== undefined && token !== undefined && PayerID !== undefined) {
        let data = {
          paymentId: paymentId,
          token: token,
          PayerID: PayerID,
        }

        this.$store.dispatch('executePaymentPayPall', data).then((response) => {
          if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            if (response.data.data.status === true) {
              window.history.replaceState({}, window.location.href, window.location.pathname);
              this.changeThankYouPopupPopup(true)
            }
          } else {
            window.history.replaceState({}, window.location.href, window.location.pathname);
            setTimeout(() => {
              this.openNotify('error', 'common_notificationPaymentErrorOccured')
            }, 200)
          }
        });
      }
    },

    methods: {
      changeThankYouPopupPopup(val) {
        this.thankYouPopupPopup = val
      },
    //
    //
    //   changeUPCCodesImport(val){
    //     this.isModalUPCCodesImport = val
    //   }
    }

  }
</script>

<style lang="scss">
  @import "../../../scss/mixins/mixins";
  @import "../../../scss/colors";

  .dashboard{
    padding-top: 24px;

    .section-label{
      margin-bottom: 16px;
    }

    &-page {

      .breadcrumbs__title {
        visibility: hidden;
      }

      .block-wrap-style {
        max-width: 100%;
      }

    }
  }

  .dashboard-row{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }

  .dashboard-col{
    padding: 0 8px;
    width: 100%;
    margin-bottom: 32px;

    //@include for-1400{
    //  &:nth-child(1){
    //    order: 1;
    //  }
    //  &:nth-child(2){
    //    order: 3;
    //  }
    //  &:nth-child(3),
    //  &:nth-child(4){
    //    order: 2;
    //  }
    //  &:nth-child(5){
    //    order: 4;
    //  }
    //  &:nth-child(6){
    //    order: 6;
    //  }
    //}

    &--20{
      width: 20%;
    }

    &--25{
      width: 25%;
    }

    &--60{
      width: 60%;
    }

    &--75{
      width: 75%;
    }

    &--80{
      width: 80%;
    }

    &--lg-33{
      @include for-1400 {
        width: 33.33333%;
      }
    }

    &--lg-50{
      @include for-1400 {
        width: 50%;
      }
    }

    &--lg-100{
      @include for-1400 {
        width: 100%;
      }
    }

    &--md-100{
      @include for-768 {
        width: 100%;
      }
    }
  }


</style>
