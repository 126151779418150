import Banner from '../../components/Banner/Banner.vue'
import SignUpForm from '../../components/SignUpForm/SignUpForm.vue'
import Successful from '../../components/Successful/Successful.vue'

export default {
  name: "SignUp",

  data(){
    return{
      successForm: false,
    }
  },

  methods:{
    successRegister() {
      this.successForm = true;
    }
  },

  components: {
    Banner,
    SignUpForm,
    Successful
  }
}
