<template>
  <div class="problem-parcel">
    <div class="section-label large"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['dashboard_problemParcel',])"></div>
      <router-link :to="$store.getters.GET_PATHS.problemsFillClaim + '?statusId=open&statusName=Open'"
                   class="site-link--alt"
      >
        {{$t('dashboard_problemParcel.localization_value.value')}} ({{count}})
      </router-link>
    </div>

    <div class="problem-parcel__list">
      <div class="problem-parcel__item"
           v-for="(item, index) in problemsParcels"
           :key="index"
      >
        <div class="problem-parcel__btn">
          <LinkButton
              :value="$t('common_edit.localization_value.value')"
              :type="'edit'"
              :link="$store.getters.GET_PATHS.problemsFillClaim + '/' + item.id"
          />
        </div>
        <v-popover
            class="site-tooltip problem-parcel__site-tooltip"
            offset="5"
            placement="top"
            trigger="hover"
        >
          <div class="problem-parcel__num">
            {{item.tracking_number}}
          </div>
          <template slot="popover">
            <p>{{ item.tracking_number }}</p>
          </template>
        </v-popover>

        <div class="problem-parcel__name">
          <ValueHelper
              :value="item"
              :deep="'user.user_personal_contact.user_full_name'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
import {queryFilterMixin} from "../../../../../mixins/routeFilterMixins/queryFilterMixin";
import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";

export default {
  name: "ProblemParcel",
  components: {
    ValueHelper,
    LinkButton,
  },

  mixins: [
      queryFilterMixin
  ],

  created() {
    let myQuery = this.createQueryBuilderObject()

    //using string value because don't want to make another API request
    myQuery.where('status', 'Open')

    let url = this.generateGetParamsFromQuery(myQuery, 5, 0)

    this.$store.dispatch('fetchFillClaim', url).then(response => {
      console.log(this.$store.getters.getFillClaim);

      this.problemsParcels = this.getRespPaginateData(response)
      this.count = this.$store.getters.getFillClaimCommonList.total
    }).catch(error => this.createErrorLog(error))
  },

  data() {
    return {
      problemsParcels: [],
      count: 0,
    }
  },
}
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";
  @import "../../../../../scss/colors";

  .problem-parcel{

    &__list{
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      box-shadow: 0px 4px 16px #E7DFD5;
      border-radius: 5px;
      overflow: hidden;
    }

    &__item{
      position: relative;
      padding: 15px 17px;


      &:nth-child(even){
        background: $borderBrownOpacity02;
      }

    }

    &__btn{
      position: absolute;
      z-index: 2;
      right: 15px;
      top: 15px;
    }

    &__num{
      font-weight: bold;
      font-size: 14px;
      line-height: 149.69%;
      color: $black;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__site-tooltip {


      ::v-deep .trigger {
        max-width: calc(100% - 120px);
      }
    }

    &__name{
      font-size: 14px;
      line-height: 148.69%;
      color: black;
    }

  }

</style>