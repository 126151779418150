<template>
    <SignUp/>
</template>

<script>
  import SignUp from './components/SignUp/SignUp.vue'

  export default {
    name: "Registration",

    components: {
      SignUp
    }
  }
</script>

<style scoped>

</style>