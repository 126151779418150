<template>
  <div class="fragment">
    <div class="dashboard-warning-block"
         v-for="(reconnectShop, index) in reconnectShops"
         :key="index"
    >
      <div class="dashboard-warning-item"
           v-bind:class="{'mb-4': index !== reconnectShops.length - 1}"
      >
        <HorizontalWarning
            class="horizontal-warning-scoped"
            :ico="true"
            :icoType="'orange-warning'"
            :textType="'black'"
            :fullWidth="true"
        >
          <template slot="text">
            <div class="dashboard-warning-item-wrap">
              <div>
                <ValueHelper
                    :value="reconnectShop"
                    :deep="'currentTranslate.text'"
                />
              </div>

              <div class="dashboard-warning-checkbox cursor-pointer"
                   @click="$emit('removeReconnectShop', reconnectShop)"
              >
                <CloseIcon/>
              </div>
            </div>
          </template>
        </HorizontalWarning>
      </div>
    </div>
  </div>

</template>

<script>
import HorizontalWarning from "../../../../coreComponents/HorizontalWarning/HorizontalWarning";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import CloseIcon from '../../../../../../public/img/modal-group/close-icon.svg?inline'

export default {
  name: "DashboardReconnectShopsWarning",
  components: {ValueHelper, HorizontalWarning, CloseIcon},

  props: {
    reconnectShops: Array,
  },

  methods: {

  },
}
</script>

<style scoped>

.horizontal-warning-scoped {
  margin-top: 0;
  margin-bottom: 0;
}

.dashboard-warning-block {
  margin-top: -10px;
  margin-bottom: -10px;
}

.dashboard-warning-checkbox {
  font-weight: normal;
  flex-shrink: 0;
}

.dashboard-warning-item-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .dashboard-warning-item-wrap {
    display: block;
  }
}
</style>
