var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"horizontal-warning"},[_c('div',{staticClass:"horizontal-warning__content"},[(_vm.ico)?_c('div',{staticClass:"horizontal-warning__ico",class:{
         'horizontal-warning__ico--warning' : !_vm.icoType,
         'horizontal-warning__ico--orange-warning' : _vm.icoType === 'orange-warning',
         }}):_vm._e(),_c('div',{staticClass:"horizontal-warning__text",class:{
         'horizontal-warning__text--black-text': _vm.textType === 'black',
         'horizontal-warning__text--full-width': _vm.fullWidth === true,
         }},[_vm._v(" "+_vm._s(_vm.text)+" "),_vm._t("text"),(_vm.tooltip)?_c('div',{staticClass:"horizontal-warning__tooltip"},[_vm._t("tooltip")],2):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }